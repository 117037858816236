import MenuTitle from 'Components/Parts/MenuTitle';
import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import BasicLayout from './Layouts/Basic';

const Wrapper = styled.section`
  padding: 20px;
  background-color: ${Colors.white};
`;

const StepLabel = styled.h2`
  color: ${Colors.blue};
  margin: 20px 0 0;
  font-size: 14px;
`;

const StyledCaption = styled.p`
  margin: 0;
  color: ${Colors.black};
`;

const StyledP = styled.p`
  margin: 0;
  color: ${Colors.gray};
`;

const LineButton = styled.button`
  display: block;
  width: 80%;
  background-color: ${Colors.lineGreen};
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  padding: 0 8px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin: 40px auto;
  text-align: center;
  font-weight: bold;
  border: none;
`;

const LineNotifyPage: FC = () => {
  const lineId = '@803eleah';

  const handleLineFollow = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.open(`line://ti/p/${lineId}`, '_blank');
    } else {
      window.open(`https://line.me/R/ti/p/${lineId}`, '_blank');
    }
  };

  return (
    <BasicLayout>
      <MenuTitle>LINEで通知を受け取る</MenuTitle>
      <Wrapper>
        <StyledCaption>
          「LINEで通知を受け取る」設定をすることで、プレイヤーとの新しいメッセージ、購入ステータスの更新、プレー終了時間などをリアルタイムで通知が届くようになります。ぜひご活用ください。
        </StyledCaption>
        <StepLabel>ステップ1</StepLabel>
        <StyledP>下部にある「LINEで通知を受け取る」ボタンをクリックしてください。</StyledP>
        <StepLabel>ステップ2</StepLabel>
        <StyledP>LINEアプリが起動しますので、2PLAY公式LINEアカウントを友だち追加してください。</StyledP>
        <StepLabel>ステップ3</StepLabel>
        <StyledP>友だち追加後、LINEアプリを閉じていただくと設定完了となります。</StyledP>
        <LineButton onClick={handleLineFollow}>LINEで通知を受け取る</LineButton>
      </Wrapper>
    </BasicLayout>
  );
};

export default LineNotifyPage;
