import { PrimaryButton } from 'Components/Parts/Buttons';
import MessageInputArea from 'Components/Parts/MessageInput/MessageInputArea';
import { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import Axios from 'axios';
import Config from 'Config/Config';
import { PlayerProfile, PlayerGame } from 'Schemes/Player';
import { InboxObject } from 'Schemes/InboxObject';
import { withRouter, RouteComponentProps, Link, match } from 'react-router-dom';
import CheckoutFullPageModal from 'Components/Checkout/CheckoutFullPageModal';
import LineFollowNotificationButton from 'Components/Parts/LineFollowNotificationButton';
import { Helmet } from 'react-helmet';
import { User } from 'Schemes/User';
import SelectGameModal from 'Components/Parts/Modals/SelectGameModal';
import { CurrentPayment, PaymentStatus } from 'Schemes/Payment';
import InboxDefaultAction from 'Components/Parts/Inbox/DefaultAction';
import MessageArea from 'Components/Parts/Inbox/MessageArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import StartPlayModal from 'Components/Parts/Modals/StartPlayModal';
import StartPlayAction from 'Components/Parts/Inbox/StartPlayAction';
import ExtendPlayAction from 'Components/Parts/Inbox/ExtendPlayAction';
import { useIsFirstPayment } from 'Hooks/useUserInboxes';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import { ProfileImage } from 'Components/Parts/ProfileImage';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;

  height: 70px;
  font-weight: 70px;
  position: fixed;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
`;

const StyledMessageArea = styled(MessageArea)<{ showLineNotify: boolean }>`
  ${(props) =>
    props.showLineNotify
      ? css`
          padding-top: 120px;
        `
      : css``}
`;

const StyledBack = styled(FontAwesomeIcon)`
  font-size: 24px;
  padding: 23px;
  width: 24px;
  color: ${Colors.black};
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;

const HeaderTitle = styled.h2`
  margin: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageInputAreaWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const MessageInputAreaContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${Colors.white};
  display: flex;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: 15%;
  text-align: center;
`;

const StyledMessageInputAreaWrapper = styled(MessageInputAreaWrapper)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LineFollowButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

type Props = {
  match: match<{ uuid: string }>;
} & RouteComponentProps;

const InboxDetailPage: FC<Props> = ({ match, history }) => {
  const playerID = match.params.uuid;

  const { isFirstPayment } = useIsFirstPayment(playerID);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [player, setPlayer] = useState<PlayerProfile | null>(null);
  const [playerGames, setPlayerGames] = useState<PlayerGame[]>([]);
  const [currentPayment, setCurrentPayment] = useState<CurrentPayment | null>(null);

  const [lastMessageFetched, setLastMessageFetched] = useState(0);
  const [lastCurrentPaymentFetched, setLastCurrentPaymentFetched] = useState(0);
  const [currentGame, setCurrentGame] = useState<PlayerGame | null>(null);
  const [purchasing, setPurchasing] = useState(false);

  const [inputMessage, setInputMessage] = useState('');
  const [sendingInboxObjects, setSendingInboxObjects] = useState([]);
  const [inboxObjects, setInboxObjects] = useState<InboxObject[]>([]);

  const [showStartPlayModal, setShowStartPlayModal] = useState(false);
  const [showSelectGameModal, setShowSelectGameModal] = useState(false);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    updateCurrentPayment(playerID);
  }, [lastCurrentPaymentFetched, playerID]);

  const updateCurrentPayment = (playerID: string) => {
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/inboxes/current_payment`, { PlayerID: playerID })
      .then((response) => {
        const data = response.data ? response.data : null;
        setCurrentPayment(data);
        setTimeout(() => {
          const date = new Date();
          setLastCurrentPaymentFetched(date.getTime());
        }, 5000);
      });
  };
  
  const scrollToBottom = () => {
    if (messagesEndRef!.current === null) return;
    messagesEndRef!.current!.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (messagesEndRef!.current === null) return

    if (!isRendered) {
      scrollToBottom();
      setIsRendered(true);
    }
  }, [messagesEndRef, isRendered, inboxObjects, sendingInboxObjects]);


  useEffect(() => {
    const getUser = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user`)
        .then((response) => {
          if (response.data === null) {
            history.push('/login');
          }
          setUser(response.data);
        });
    };

    const getPlayerGames = async () => {
      const response = await Axios.get(`${Config.API_HOST}/players/s/${playerID}/games`);
      setPlayerGames(response.data);
    };

    const getCurrentPayment = async () => {
      await Axios.create({ withCredentials: true })
        .post(`${Config.API_HOST}/user/inboxes/current_payment`, { PlayerID: playerID })
        .then((response) => {
          const data = response.data ? response.data : null;
          setCurrentPayment(data);
        });
    };

    getPlayerGames();
    getUser();
    getCurrentPayment();
  }, [history, playerID]);

  useEffect(() => {
    if (player === null) fetchPlayer(playerID);
    fetchMessage(playerID);
  }, [playerID, lastMessageFetched, player]);

  const fetchPlayer = (playerID: string) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/players/s/${playerID}`)
      .then((response) => {
        setPlayer(response.data);
      });
  };

  const fetchMessage = (playerID: string) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/user/inboxes/${playerID}`)
      .then((response) => {
        setInboxObjects((prev) => {
          const newObjects: InboxObject[] = Object.assign([], prev, response.data);
          return newObjects;
        });
        setSendingInboxObjects([]);
      })
      .then(() => {
        setTimeout(() => {
          const date = new Date();
          setLastMessageFetched(date.getTime());
        }, 5000);
      });
  };

  const sendMessage = () => {
    if (inputMessage === '') return;
    setSendingInboxObjects((prev) => Object.assign([], prev, [inputMessage]));
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/message`, { PlayerID: playerID, Message: inputMessage })
      .then((_) => {
        setInputMessage('');
      });
  };

  // TODO: currentPayment?.CaptureTimelimitAtの日付を判定する必要がある
  const renderActionArea = () => {
    if (currentPayment?.Status === PaymentStatus.Authorized) {
      return <StartPlayAction setShowStartPlayModal={setShowStartPlayModal} currentPayment={currentPayment} />;
    } else if (currentPayment?.Status === PaymentStatus.Captured) {
      return <ExtendPlayAction setPurchasing={setPurchasing} currentPayment={currentPayment} />;
    }

    return (
      <InboxDefaultAction
        currentGame={currentGame}
        setShowSelectGameModal={setShowSelectGameModal}
        setPurchasing={setPurchasing}
      />
    );
  };

  if (player === null || user === null) {
    return <></>;
  }

  if (purchasing) {
    return (
      <CheckoutFullPageModal
        currentPayment={currentPayment}
        game={currentGame}
        closeModal={() => setPurchasing(false)}
        userID={user.UUID}
        player={player}
        isFirstPayment={isFirstPayment}
        afterCheckoutAction={() => {
          fetchMessage(playerID);
          updateCurrentPayment(playerID);
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Wrapper>
        <Header>
          <HeaderTitle>
            <Link to={`/players/${player.UUID}`}>
              <ProfileImage profileImage={playerMainProfileImage(player)} />
            </Link>
            {player.Name}
          </HeaderTitle>
          <Link to="/inboxes">
            <StyledBack icon={faAngleLeft} />
          </Link>
          <LineFollowButtonWrapper>
            <LineFollowNotificationButton />
          </LineFollowButtonWrapper>
        </Header>
        <StyledMessageArea
          showLineNotify={!user.LineConnected}
          inboxObjects={inboxObjects}
          sendingInboxObjects={sendingInboxObjects}
          player={player}
        />
        <div ref={messagesEndRef} />
        <StyledMessageInputAreaWrapper>
          {renderActionArea()}

          <MessageInputAreaContent>
            <MessageInputArea onChange={setInputMessage} inputMessage={inputMessage} />
            <StyledPrimaryButton value="送信" onClick={sendMessage} />
          </MessageInputAreaContent>
        </StyledMessageInputAreaWrapper>

        <SelectGameModal
          isOpen={showSelectGameModal}
          games={playerGames}
          changeCurrentGame={setCurrentGame}
          closeModal={() => setShowSelectGameModal(false)}
        />
        <StartPlayModal
          isOpen={showStartPlayModal}
          playerID={playerID}
          closeModal={() => setShowStartPlayModal(false)}
          afterStartPlayAction={() => {
            fetchMessage(playerID);
            updateCurrentPayment(playerID);
          }}
        />
      </Wrapper>
    </>
  );
};

export default withRouter(InboxDetailPage);
