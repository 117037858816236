import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'Utils/Colors';
import Config from 'Config/Config';
import LineFollowModal from './Modals/LineFollowModal';

const NotifyModalButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: none;
  text-decoration: none;
  color: ${Colors.black};
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  padding: 28px;
  cursor: pointer;
`;

const NotificationCircle = styled.p`
  z-index: 0;
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${Colors.pink};
  right: 24px;
  top: 6px;
`;


const LineFollowNotificationButton = () => {
  const [notifyModalOpen, setNotifyModalOpen] = useState(false);
  const [notifyIsUnRead, setNotifyIsUnread] = useState(false);

  const onLineNotifyModalOpen = () => {
    setNotifyModalOpen(true);

    // 未読のお知らせがある場合
    if (notifyIsUnRead) {
      Axios.create({ withCredentials: true })
        .post(`${Config.API_HOST}/user/line_follows_notifications`)
        .then((res)=>{
          setNotifyIsUnread(false);
          console.log(res);
        })
    }
  }

  useEffect(()=>{
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/user/line_follows_notifications`)
      .then((response) => {
        const dataCount = response.data ? response.data.length : 0;
        setNotifyIsUnread(dataCount === 0);
      });
  }, [])

  return (
    <>
      <NotifyModalButton onClick={onLineNotifyModalOpen}>
        {notifyIsUnRead && <NotificationCircle />}
        <Icon icon={faBell} />
      </NotifyModalButton>
      <LineFollowModal
        isOpen={notifyModalOpen}
        setIsOpen={setNotifyModalOpen}
      />
    </>
  )
}

export default LineFollowNotificationButton;
