
import styled from 'styled-components';

import CommonModal from 'Components/Parts/Modals/CommonModal';
import Colors from 'Utils/Colors';

const LineButton = styled.button`
  display: block;
  width: 80%;
  background-color: ${Colors.lineGreen};
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  padding: 0 8px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin: 40px auto;
  text-align: center;
  font-weight: bold;
  border: none;
`;

const StyledUl = styled.ul`
  padding: 0;
  list-style: none;
`;

const LineFollowModal = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: any }) => {
  const lineId = '@803eleah';
  const handleLineFollow = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.open(`line://ti/p/${lineId}`, '_blank');
    } else {
      window.open(`https://line.me/R/ti/p/${lineId}`, '_blank');
    }
  };

  return (
    <CommonModal title="お知らせ" isOpen={isOpen} closeModal={() => { setIsOpen(false) }}>
      <strong>2PLAYをLINEで通知受け取ろう！</strong>
      <StyledUl>
        <li>・新しいメッセージや購入状況、プレー終了時間などがリアルタイムで届きます</li>
        <li>・プレゼントキャンペーンのお知らせもLINEで受け取れます</li>
      </StyledUl>
      <strong>設定はかんたん</strong>
      <StyledUl>
        <li>1. 下の「LINEで通知を受け取る」ボタンをタップ</li>
        <li>2. LINEアプリが起動したら、2PLAY公式LINEアカウントを友だち追加するだけ！<br />(PCの場合はQRコードが表示されます)</li>
      </StyledUl>
      <LineButton onClick={handleLineFollow}>LINEで通知を受け取る</LineButton>
    </CommonModal>
  )
}

export default LineFollowModal;
